.container {
  .form_control_container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
    .form_control_left_block {
      display: flex;
      gap: 10px;
      align-items: center;
      // cursor: pointer;
    }
    .label {
      font-size: 14px;
      width: 100%;
      max-width: 124px;
      overflow-wrap: break-word;
    }

    .setting_icon {
      margin-bottom: '4px';
      cursor: pointer;
    }

    .close {
      margin-bottom: '4px';
      cursor: pointer;
      display: none;
      transition: display 0.5s ease;
    }
  }

  .form_control_container:hover .close {
    display: block;
  }

  .add_calendar_label {
    font-weight: 600;
    font-size: 14px;
  }
}
