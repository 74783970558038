@import 'styles/varibles.scss';
@import '~utils/styles/styles.scss';

.coach-website-navbar {
  .links-contianer {
    padding: 15px 2px;
    display: flex;

    .nav-logo-img {
      padding: 0px;
      margin: auto 20px auto 98px;
    }

    .nav-bar-link {
      margin: auto 20px;
      padding: 5px 2px;
    }

    .message-btn {
      margin: auto 98px auto auto;
    }
  }
}

#smallScreen {
  display: none;
}

.intro-image {
  background-image: url('/intro.png');
  width: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-center {
  text-align: center;
}

.coach-website-links {
  .container {
    width: 100%;
    height: auto !important;
    padding-right: 10%;
    padding-left: 10%;
    max-width: unset;
  }

  .container:hover .image {
    opacity: 1 !important;
  }
}

.about-new-text {
  padding: 10px 0 0 0;
  color: #323338;
}

.about-new-text p {
  font-weight: 300;
  line-height: 1.7;
}

.about-new-text p strong {
  font-weight: 700 !important;
}

.about-new-text p:not(:last-child) {
  margin-bottom: 1.5em;
}

.about-new-text .row {
  align-items: center;
  align-content: center;
}

.about-new-text .row:not(:last-child) {
  margin-bottom: 34px;
}

.about-new-text .row:nth-child(odd) {
  flex-direction: row-reverse;
}

.about-new-text .images {
  position: relative;
  display: flex;
  width: 100%;
}

.about-new-text .images .image {
  border-radius: 16px;
  overflow: hidden;
  height: 302px;
  flex: 1;
}

.about-new-text .images .image:not(:last-child) {
  margin-right: 16px;
}

.about-new-text .images .image picture {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
}

.other-links-profile-pic {
  margin-top: 65px;

  > div {
    margin: auto;
    width: 98px;
    height: 98px;
    margin-bottom: 67px;
  }
}

.website-mobile-nav:hover {
  outline: unset !important;
}

.about-new-text .images_wrap {
  position: relative;
}

.about-new-text .images_wrap img {
  background-color: #efefef;
}

.about-new-text .s {
  position: absolute;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
}

.about-new-text .s-1 {
  width: 233px;
  height: 233px;
  top: 50%;
  transform: translateY(-50%);
  left: -163px;
  z-index: -1;
  background-image: url(//monday.com/p/wp-content/themes/monday-news/css/../images/about-2022/text-block-s-1.svg);
  background-position: center bottom;
}

.about-new-text .s-2 {
  width: 28px;
  height: 227px;
  bottom: 53px;
  right: -14px;
  background-image: url(//monday.com/p/wp-content/themes/monday-news/css/../images/about-2022/text-block-s-2.svg);
  background-position: center top;
}

.about-new-text .s-3 {
  width: 192px;
  height: 192px;
  bottom: -90px;
  right: 20px;
  background-image: url(//monday.com/p/wp-content/themes/monday-news/css/../images/about-2022/text-block-s-3.svg);
  background-position: center top;
  z-index: -1;
}

.about-new-text .row > div {
  padding: 24px;
}

@media (max-width: $tablet) {
  .about-new-text .row > div {
    padding: 6px;
  }
}

@media (max-width: 1199px) {
  .about-new-text p {
    font-size: var(--fs14);
  }

  .about-new-text .images .image {
    height: 210px;
  }

  .about-new-text .images .image:first-child {
    width: 110px;
  }
}

@media (max-width: $desktop) {
  .about-new-text .row:first-child {
    margin-top: 40px;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.explaination-text {
  margin-bottom: 20px !important;
  text-align: center;
  font-size: 30px !important;
}

@media (max-width: $tablet) {
  .about-new-text {
    padding: 10px 0 15px 0;
  }

  .explaination-text {
    font-size: 20px !important;
    position: relative;
    margin: 0;
  }
}

@media (max-width: $mobile-size) {
  .contribution-heading {
    font-size: 24px;
  }

  .explaination-text {
    margin-left: 10px !important;
    margin: 10px 0px;
    margin-top: 5px;
    position: relative;
  }
}

@media (max-width: 330px) {
  .explaination-text {
    margin-top: 0px;
  }
}

#slider {
  display: none;
}

.link {
  padding: 20px;
  border-radius: 10px;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.link-section-container {
  margin: 15px 0px;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.footer-container {
  border-top: 1px solid rgba(223, 227, 228, 1);
  padding-top: 48px;
  padding-bottom: 48px;

  &.dark-mode {
    border-color: white;
  }

  .avatar {
    margin-bottom: 14px;
    width: 103px;
    height: 103px;
  }

  .privary-policy {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      margin-bottom: 0px;
    }

    .privacy-policy-link:hover {
      text-decoration: underline;
    }
  }
}

.link-section-container:hover {
  background-color: #2a2c2e !important;
  cursor: pointer;
}

.flex-item {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.flex-item > svg {
  color: #d08acd;
}

nav {
  display: flex;
  /* 1 */
  padding: 1rem 2rem;
  /* 3 */
  background: #cfd8dc;
  /* 4 */
}

nav ul {
  display: flex;
  /* 5 */
  list-style: none;
  /* 6 */
}

nav li {
  padding-left: 1rem;
  /* 7! */
}

.text-center {
  text-align: center;
}

.mt-50 {
  margin-top: 50px;
}

.section-margin {
  position: relative;
  width: auto;
}

.other-section {
  display: inline-block;
  padding-bottom: 80px;
  width: 100%;

  .section-header-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    text-align: center;
    letter-spacing: 0.02em;
    margin: 67px 0 67px 0;
    padding: 0 10px;
  }

  .question-section-header-text1 {
    font-size: 56px;
    font-weight: 600;
    margin: 120px 0 0 0;
  }

  .question-section-header-text2 {
    font-size: 56px;
    font-weight: 600;
    margin: 5px 0 80px 0;
  }

  .website-section-container {
    padding: 0 10%;
    overflow: hidden;

    .website-contributions {
      .website-contribution-row {
        border-radius: 16px;
        background: #fafafa;

        .image-container {
          img {
            width: 100%;
            object-fit: contain;
            border-radius: 5px;
          }
        }
      }
    }

    .text-side {
      h1 {
        margin-top: 0;
      }
    }
  }

  @media (max-width: $tablet) {
    .section-header-text {
      margin-top: 32px;
      font-size: 32px;
    }

    .question-section-header-text1 {
      font-size: 32px;
      margin: 80px 0 0 0;
    }

    .question-section-header-text2 {
      font-size: 32px;
      margin: 5px 0 32px 0;
    }

    .website-section-container {
      padding: 0 5%;

      .website-contributions {
        .website-contribution-row {
          padding: 10px;
          margin-bottom: 24px;

          .contribution-heading {
            margin-top: 24px;
          }
        }
      }
    }
  }
}

.about-section {
  display: inline-block;
  width: 100%;

  .about-sub-section {
    .bio-section {
      &.no-image {
        padding: 106px 0;

        @media (max-width: $tablet) {
          padding: 53px 0;
        }
      }

      .intro-card {
        overflow: visible;
        position: absolute;
        top: 50px;
        bottom: 50px;
        margin-top: auto;
        margin-bottom: auto;
        height: fit-content;

        &.no-image {
          margin: auto;
          width: 50%;
          max-width: unset;
          position: initial;
          top: 0;
          bottom: 0;

          @media (max-width: $desktop) {
            width: 60%;
          }

          @media (max-width: $tablet) {
            width: 80%;
          }

          .profile-bio-paragraph {
            -webkit-line-clamp: 10;
          }
        }
      }
    }

    .header-text {
      padding: 0 5%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      text-align: center;
      letter-spacing: 0.02em;
      margin: 67px 0 0 0;
      padding: 0 10px;
    }

    .subheader-text {
      margin: 22px 0 0 0;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      padding: 0 10px;
    }

    .primary-video-image-container {
      text-align: center;

      .primary-video-image {
        display: inline-block;
        width: 100%;
        padding: 0;

        .primary-banner-image {
          width: 100%;
          margin-top: 80px;
          @media (max-width: $mobile-size) {
            margin-top: 10px;
          }
        }

        .primary-banner-video {
          position: relative;
          width: 100%;
          margin-top: 80px;
          @media (max-width: $mobile-size) {
            margin-top: 10px;
          }

          .react-player {
            video {
              width: 100%;
              z-index: 1;
              margin-bottom: -8px;
            }
          }
        }
      }

      .tag-container {
        position: relative;
        width: 100%;
        padding: 20px 5px;

        button {
          border-radius: 4px;
          margin: 5px 6px;
        }
      }
    }

    .bio-profile-pic {
      position: absolute;
      margin-top: -26px;
      margin-left: calc(50% - 26px);
      z-index: 9;

      > div {
        margin: auto;
        width: 52px;
        height: 52px;
        margin-bottom: 0px;
      }
    }

    .bio-container {
      padding: 15px 20px 35px 20px !important;

      h1 {
        margin: 16px 0;
        text-align: center;
        font-size: 32px;
        font-family: 'Poppins';
        font-weight: 500;
      }
    }

    .section-header-text {
      padding: 0 5%;
      text-align: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      line-height: 126%;
      font-size: 56px;
      margin: 120px 0 80px 0;
    }

    .question-section-header-text1 {
      font-size: 56px;
      font-weight: 600;
      margin: 120px 0 0 0;
    }

    .question-section-header-text2 {
      font-size: 56px;
      font-weight: 600;
      margin: 5px 0 80px 0;
    }

    .website-section-container {
      padding: 0 10%;
      overflow: hidden;

      .website-contributions {
        .website-contribution-row {
          border-radius: 16px;
          background: #fafafa;

          .image-container {
            img {
              width: 100%;
              object-fit: contain;
              border-radius: 5px;
            }
          }
        }
      }

      .text-side {
        h1 {
          margin-top: 0;
        }
      }
    }
  }

  #video-section-webview {
    .tags {
      cursor: default;
    }

    .play-pause-button {
      svg {
        width: 74px;
        height: 74px;
      }

      position: absolute;
      top: calc(50% - 39px);
      left: calc(50% - 39px);
      z-index: 9;
      cursor: pointer;
    }
  }

  .other-links-profile-pic {
    display: none;
  }

  @media (max-width: $tablet) {
    .about-sub-section {
      .bio-section {
        .intro-card {
          margin-top: -22%;
          position: relative;
          margin-left: calc(50% - 187.5px);
          margin-right: calc(50% - 187.5px);
          margin-bottom: 70px;
          box-shadow: 0px 6px 30px 0px rgba(33, 92, 115, 0.32);
        }
      }

      .header-text {
        margin-top: 32px;
        font-size: 32px;
      }

      .subheader-text {
        margin-top: 8px;
        font-size: 16px;
      }

      .primary-video-image-container {
        text-align: center;

        .primary-banner-image {
          margin-top: 10px;
        }

        .primary-banner-video {
          margin-top: 10px;
        }

        .tag-container {
          button {
            font-size: 10px !important;
            border-radius: 4px;
          }
        }
      }

      .bio-container {
        padding: 25px 20px 35px 20px !important;

        h1 {
          font-size: 24px;
        }

        .profile-bio-paragraph {
          font-size: 14px;
        }
      }

      .bio-profile-pic {
        margin-top: -17px;
        margin-left: calc(50% - 20px);

        > div {
          width: 40px;
          height: 40px;
        }
      }

      .website-intro-container {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .section-header-text {
        font-size: 32px;
        margin: 80px 0 32px 0;
      }

      .question-section-header-text1 {
        font-size: 32px;
        margin: 80px 0 0 0;
      }

      .question-section-header-text2 {
        font-size: 32px;
        margin: 5px 0 32px 0;
      }

      .website-section-container {
        padding: 0 5%;

        .website-contributions {
          .website-contribution-row {
            padding: 10px;
            margin-bottom: 24px;

            .contribution-heading {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 396px) {
    .about-sub-section {
      .bio-section {
        .intro-card {
          position: relative;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}

#video-section-mobileview iframe,
#video-section-mobileview video {
  border-radius: 14px;
  width: 100% !important;
  height: auto !important;
  z-index: 1;
}

.mb-50 {
  margin-bottom: 50px;
}

#foreground-text {
  margin: 15px 0px 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

@media (max-width: $tablet) {
  .header-text {
    font-size: 33px;
  }

  #foreground-text {
    top: 20%;
  }
}

@media (max-width: $mobile-size) {
  .header-text {
    margin: 0px;
    font-size: 28px;
  }

  #foreground-text {
    font-size: 16px;
    position: relative;
    margin: 10px 0;
  }
}

.topnav-right {
  float: right;
}

.video-section-message {
  outline: white1px solid white;
  padding: 8px !important;
  margin: 0px 7px;
  display: inline;
  top: 7px;
  border-radius: 7px;
  position: relative;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

@media (max-width: $mobile-size) {
  div.scrollmenu a {
    padding: 7px;
    margin: 0px 3px;
  }

  .message-btn {
    padding: 2px !important;
    margin: 0px 10px !important;
  }
}

div.scrollmenu a:hover {
  font-weight: 700;
}

div.scrollmenu::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari and Opera */
}

div.scrollmenu {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#video-section-mobileview {
  display: none;
}

#custom-button:hover {
  outline: none !important;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;
  /* if you want buttons in several lines */
}

.inline-button {
  min-width: 50px;
}

.responsive-small-font {
  font-size: 14px !important;
}

.btn-small,
.btn-small:active,
.btn-small.active {
  font-size: 16px;
  color: #333;
  background: #f7f7f7;
  min-width: 100px;
  padding: 9px;
}

.btn-small:hover {
  outline: none;
  color: white;
}

.btn-main,
.btn-main:active,
.btn-main.active {
  font-size: 18px;
  color: #ffffff;
  background: #245bb8;
  min-width: 150px;
  min-height: 50px;
  padding: 12px;
}

.btn-main:hover {
  color: #ffffff;
  background: #f89012;
}

/*  medium-sized devices */
@media all and (min-width: 501px) and (max-width: 800px) {
  .btn-small,
  .btn-small:active,
  .btn-small.active {
    min-width: 50px;
    padding: 6px;
  }

  .btn-main,
  .btn-main:active,
  .btn-main.active {
    font-size: 18px;
    min-width: 100px;
    padding: 9px;
  }

  .responsive-small-font {
    font-size: 14px !important;
  }
}

/*  small devices */
@media all and (min-width: 401px) and (max-width: 500px) {
  .btn-small,
  .btn-small:active,
  .btn-small.active {
    min-width: 50px;
    padding: 6px;
  }

  .btn-main,
  .btn-main:active,
  .btn-main.active {
    font-size: 16px;
    min-width: 100px;
    padding: 9px;
  }

  .responsive-small-font {
    font-size: 12px !important;
  }
}

/*  extra small devices */
@media all and (min-width: 315px) and (max-width: 400px) {
  .btn-small,
  .btn-small:active,
  .btn-small.active {
    min-width: 45px;
    padding: 6px;
  }

  .btn-main,
  .btn-main:active,
  .btn-main.active {
    font-size: 14px;
    min-width: 90px;
    padding: 9px;
  }

  .responsive-small-font {
    font-size: 10px !important;
  }
}

/*  extra super small devices */
@media all and (max-width: 314px) {
  .btn-small,
  .btn-small:active,
  .btn-small.active {
    min-width: 40px;
    padding: 6px;
  }

  .btn-main,
  .btn-main:active,
  .btn-main.active {
    font-size: 9px;
    min-width: 70px;
    padding: 6px;
  }

  .responsive-small-font {
    font-size: 8px !important;
  }
}

.read-or-hide {
  color: #d08acd;
  cursor: pointer;
}

.active-link {
  font-weight: 700;
  border-bottom: 1px solid #d08acd;
}

.css-46bh2p-MuiCardContent-root {
  padding: 10px 10px 16px 10px !important;
}

.centered-div {
  margin-top: 50px;
}

@media (max-width: $desktop) {
  .centered-div {
    margin-top: 15%;
  }
}

@media (max-width: $tablet) {
  .centered-div {
    margin-top: 25%;
  }
}

@media (max-width: $mobile-size) {
  .centered-div {
    margin-top: 30%;
  }
}

//Hide logo on nav
@media (max-width: $mobile-size) {
  #nav-logo-img {
    display: none;
  }
}

.primary-video-image-container {
  text-align: center;

  .primary-video-image {
    display: inline-block;
    padding: 20px 0px;
    width: 90%;

    .primary-banner-video {
      position: relative;
    }
  }
}

.tag-container {
  text-align: center;

  .inline-btn {
    margin: 0.25% 0.25%;
    display: inline-block;
  }

  @media all and (min-width: 501px) and (max-width: 800px) {
    padding-right: 10%;
    padding-left: 10%;
  }
}
