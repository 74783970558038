.create-post-root {
  display: flex;
  flex-grow: 1;
  border-radius: 23px;
}

.create-post-container {
  display: flex;
  flex-grow: 1;
  border-radius: 5px;
  line-height: inherit;

  .Mui-disabled {
    -webkit-text-fill-color: black !important;
    opacity: unset !important;
  }
}

.create-post-container-unified {
  display: flex;
  width: 90% !important;
  flex-grow: 1;
  border-radius: 30px;
  line-height: inherit;
}

.create-post-container-dark-themed {
  display: flex;
  flex-grow: 1;
  background: #252728;
  border-radius: 5px;
  line-height: inherit;

  .Mui-disabled {
    -webkit-text-fill-color: white !important;
    opacity: unset !important;
  }
}

.create-post-container-dark-themed-unified {
  display: flex;
  width: 90%;
  flex-grow: 1;
  background: #252728;
  border-radius: 30px;
  line-height: inherit;
}

.create-post-mention-input-container {
  flex: auto;
  flex-grow: 1;
  .mentions__highlighter {
    min-height: auto;
    padding: 10px;
    font-size: 16px;
  }
}

.create-post-mention-input-container-unified {
  flex: auto;
  flex-grow: 1;
}

.create-post-mention-input-container-dark-themed {
  flex: auto;
  flex-grow: 1;

  .mentions__highlighter {
    min-height: auto;
    padding: 10px;
    font-size: 16px;
  }

  textarea {
    color: white;
    &::placeholder {
      color: white !important;
    }
  }
}

.create-post-mention-input-container-dark-themed-unified {
  flex: auto;
  flex-grow: 1;

  textarea {
    color: white;
    &::placeholder {
      color: white !important;
    }
  }
}

.create-post-comment-mention-input-container {
  flex: auto;
  flex-grow: 1;
  padding: 0.5rem;
  max-width: 610px;
}

.create-comment-mention-input-container {
  flex: auto;
  flex-grow: 1;
}
.create-comment-mention-input-container-dark-themed {
  flex: auto;
  flex-grow: 1;

  textarea {
    color: white;
    &::placeholder {
      color: white !important;
    }
  }
}

.create-post-icons-container {
  flex-flow: row !important;
  display: flex;
  align-items: end;
  padding: 1rem 0;
  align-content: space-between;

  label {
    margin-bottom: 0px;
  }

  .icon-container {
    &:last-child {
      margin-right: 10px;
    }
  }
}

// .icon-container {
//   width: 2.5rem;
//   height: 2.5rem;
// }

.create-post-edit-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-flow-column {
  flex-flow: column;
}

.align-items-start {
  align-items: center !important;
}

.community-group-info {
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 756px) {
    max-width: 120px;
  }

  .group-image {
    width: 46px;
    height: 46px;
    font-size: 32px;
    object-fit: contain;
  }

  .group-title {
    font-size: 16px;
    font-weight: 900;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
}
